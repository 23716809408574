import React, { useEffect, useState } from "react";
import {
  Grid,
  Box,
  Typography,
  ButtonGroup,
  Dialog,
  Button,
  TextField,
  Rating,
} from "@mui/material";
import { Link, NavLink, useParams } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { useApp } from "../../context/appContext";
import OrderViewHeader from "./OrderViewHeader";
import OrderItemsView from "./OrderItems";
import OrderViewFooter from "./OrderFooter";
import OrderDeliveryAddress from "./OrderDeliveryAddress";
import OrderTimeline from "./OrderTimeline";
import OrderTakeAwayAddress from "./OrderTakeAwayAddress";
import locations from "../../services/locations";
import OrderTimelineMobile from "./OrderTimelineMobile";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import StoreLogoHeader from "../../layouts/FullLayout/Sidebar/storeLogoHeader";
import StoreLogo from "../../layouts/FullLayout/Sidebar/storeLogo";
import moment from "moment/moment";
import axios from "axios";

const OrderReviewPage = () => {
  // 2
  const {
    state,
    getData,
    getSingleData,
    postData,
    populateCompany,
    setAddress,
    populateData,
    patchData,
    setConsumeType,
    setCustomer,
  } = useApp();

  const { restaurantName, orderId, reviewId } = useParams();

  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const [orderBranch, setOrderBranch] = useState(null);

  ///RATING
  const [ratingItems, setRatingItems] = useState(null);
  const [ratingQuality, setRatingQuality] = useState(null);
  const [ratingDificulty, setRatingDificulty] = useState(null);
  const [ratingDeliveryTime, setRatingDeliveryTime] = useState(null);
  const [ratingPrice, setRatingPrice] = useState(null);
  const [ratingSupport, setRatingSupport] = useState(null);

  //RATING SUGGESTIONS
  const [ratingSuggestionItems, setRatingSuggestionItems] = useState(null);
  const [ratingSuggestionQuality, setRatingSuggestionQuality] = useState(null);
  const [ratingSuggestionDificulty, setRatingSuggestionDificulty] =
    useState(null);
  const [ratingSuggestionDeliveryTime, setRatingSuggestionDeliveryTime] =
    useState(null);
  const [ratingSuggestionPrice, setRatingSuggestionPrice] = useState(null);
  const [ratingSuggestionSupport, setRatingSuggestionSupport] = useState(null);

  //EXTRA FIELDS
  const [observations, setObservations] = useState(null);
  const [reasonToConsume, setReasonToConsume] = useState(null);
  const [recommendationScore, setRecommendationScore] = useState(null);

  //ORDER
  const [currentOrder, setCurrentOrder] = useState(null);
  const [currentReview, setCurrentReview] = useState(null);

  const [finished, setFinished] = useState(false);

  //SUBMIT BTN
  const [loadingButton, setLoadingButton] = useState(false);

  useEffect(() => {
    console.log("currentReview", currentReview);
  }, [currentReview]);

  useEffect(() => {
    console.log("currentOrder", currentOrder);
  }, [currentOrder]);

  const getReviewData = () => {
    console.log("orderId", orderId);

    getData(locations.orders, {
      _id: orderId,
    })
      .then(async (res) => {
        console.log("res from order query", res);
        //console.log("res review single data", res);
        //NOW GET THE ORDER
        if (res.data.length) {
          await populateCompany(restaurantName);
          await populateData(res.data[0].branch);
          setCurrentOrder(res.data[0]);
          getSingleData(locations.reviews, res.data[0].orderReview).then(
            (or) => {
              console.log("res from or", or);
              if (or) {
                setCurrentReview(or);
                if (or.finished) {
                  setFinished(true);
                }
              }
              //console.log("order from uuid", or);
            }
          );
        }
      })
      .catch((e) => {
        //console.log("e review", e);
      });
  };

  useEffect(() => {
    getReviewData();
  }, []);

  const handleSubmitRating = () => {
    //CRIAR UM TOKEN PRO PATCH EXCLUSIVO DESSA PROPRIEDADE

    ////console.log("currentOrder", currentOrder.seq);

    setLoadingButton(true);

    let user = currentOrder.customer;
    delete user.addresses;

    const ratingObj = {
      quality: {
        points: ratingQuality,
        notes: ratingSuggestionQuality,
      },
      items: { points: ratingItems, notes: ratingSuggestionItems },
      dificulty: { points: ratingDificulty, notes: ratingSuggestionDificulty },
      deliveryTime: {
        points: ratingDeliveryTime,
        notes: ratingSuggestionDeliveryTime,
      },
      price: { points: ratingPrice, notes: ratingSuggestionPrice },
      support: { points: ratingSupport, notes: ratingSuggestionSupport },
      notes: observations,
      reasonToConsume: reasonToConsume,
      recommendationScore: recommendationScore,
    };
    //console.log("ratingObj", ratingObj);

    patchData(locations.reviews, currentReview._id, {
      title: `Pedido #${currentOrder.seq}`,
      rating: ratingObj,
      seq: currentOrder.seq,
      customer: user,
      orderId: currentOrder._id,
      sendTo: state.branch && state.branch.whatsAppGroupId,
      finished: true,
      finishedAt: moment().toISOString(),
    }).then((res) => {
      //POST TO ENDPOINT
      console.log("review res", res);

      axios
        .post(
          "https://n8n.dnaservico.com.br/webhook/avaliacao-contratante",
          res
        )
        .then((r) => {
          //console.log("response from endpoint", r);
          setFinished(true);
          setLoadingButton(false);
          handleCloseModal();
        });

      //console.log("review", res);
    });

    //THEN
    /* setTimeout(() => {
      //console.log("closing modal after then");
      handleCloseModal();
    }, 2000); */
    //PATCH DATA
  };

  /*   useEffect(() => {
    //console.log("moment", moment().toISOString());
  }, []); */

  function perc2color(perc, min, max) {
    var base = max - min;

    if (base == 0) {
      perc = 100;
    } else {
      perc = ((perc - min) / base) * 100;
    }
    var r,
      g,
      b = 0;
    if (perc < 50) {
      r = 255;
      g = Math.round(5.1 * perc);
    } else {
      g = 255;
      r = Math.round(510 - 5.1 * perc);
    }
    var h = r * 0x10000 + g * 0x100 + b * 0x1;
    return "#" + ("000000" + h.toString(16)).slice(-6);
  }

  function ColorLuminance(hex, lum) {
    // validate hex string
    hex = String(hex).replace(/[^0-9a-f]/gi, "");
    if (hex.length < 6) {
      hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }
    lum = lum || 0;

    // convert to decimal and change luminosity
    var rgb = "#",
      c,
      i;
    for (i = 0; i < 3; i++) {
      c = parseInt(hex.substr(i * 2, 2), 16);
      c = Math.round(Math.min(Math.max(0, c + c * lum), 255)).toString(16);
      rgb += ("00" + c).substr(c.length);
    }

    return rgb;
  }

  const RatingButtons = () => {
    let buttons = [];

    for (let i = 0; i <= 10; i++) {
      buttons.push(
        <Box
          sx={{
            width: {
              xs: "15px",
              md: "30px",
            },
            backgroundColor:
              recommendationScore !== i
                ? "#FFFFFF"
                : ColorLuminance(perc2color(i, 0, 10), -0.15),
            borderRadius: "5px",
            textAlign: "center",
            padding: "5px 2px",
            color:
              recommendationScore === i
                ? "#FFFFFF"
                : ColorLuminance(perc2color(i, 0, 10), -0.15),
            border: `2px solid ${ColorLuminance(perc2color(i, 0, 10), -0.15)}`,
          }}
          onClick={() => {
            //console.log("recommendation rate", i);
            setRecommendationScore(i);
          }}
          size="large"
        >
          <Typography sx={{ fontWeight: "bolder" }}>{i}</Typography>
        </Box>
      );
    }

    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        {buttons}
      </Box>
    );
  };

  ///RATING

  return currentOrder && state.branch ? (
    <>
      <Box>
        <Box
          sx={{
            padding: "25px",
          }}
        >
          {!finished ? (
            <>
              <Box
                sx={{
                  textAlign: "center",
                  paddingTop: "50px",
                  marginBottom: "25px",
                }}
              >
                {/* <StoreLogoHeader /> */}
                <StoreLogo
                  logo={
                    state.company &&
                    state.company.settings &&
                    state.company.settings.logoURL
                  }
                />
              </Box>
              <Box sx={{ textAlign: "left" }}>
                <Box sx={{ marginBottom: "10px" }}>
                  <Typography variant="h4" sx={{ textAlign: "center" }}>
                    Adorariamos saber sua opinião sobre
                    <br /> nosso cardápio digital!
                  </Typography>
                </Box>
                <Box sx={{ marginBottom: "15px" }}>
                  <Typography variant="h6" sx={{ textAlign: "center" }}>
                    Classifique de 1 a 5 estrelas cada tópico. Ao final, se
                    desejar, nos escreva uma mensagem contando como foi sua
                    experiência :)
                  </Typography>
                </Box>
                <Box sx={{ marginBottom: "50px" }}>
                  <Typography
                    variant="h6"
                    sx={{
                      textAlign: "center",
                      fontSize: "12px",
                    }}
                  >
                    Campos marcados com asterísco (
                    <span style={{ color: "#F00" }}>*</span>) são de
                    preenchimento obrigatório.
                  </Typography>
                </Box>
                {/* ITEM 1 */}
                <Box sx={{ marginBottom: "50px" }}>
                  <Box>
                    <Typography variant="h4">
                      1 - Como você avalia o nosso{" "}
                      <span style={{ fontWeight: "bolder" }}>ATENDIMENTO?</span>
                      <span style={{ color: "#F00" }}>*</span>
                    </Typography>
                  </Box>
                  <Rating
                    onChange={(e, newValue) => {
                      //console.log("rating", newValue);
                      setRatingSupport(newValue);
                    }}
                    size="large"
                    sx={{
                      marginTop: "10px",
                      marginBottom: "10px",
                      fontSize: "50px",
                    }}
                  />
                  {ratingSupport && ratingSupport < 5 && (
                    <Box>
                      <Typography variant="h5" sx={{ marginBottom: "10px" }}>
                        O que motivou a sua nota?
                      </Typography>
                      <TextField
                        fullWidth
                        placeholder="Digite sua resposta"
                        onChange={(e) => {
                          setRatingSuggestionSupport(e.target.value);
                        }}
                      />
                    </Box>
                  )}
                </Box>
                {/* ITEM 1 */}
                <Box sx={{ marginBottom: "50px" }}>
                  <Box>
                    <Typography variant="h4">
                      2 - Como você avalia a{" "}
                      <span style={{ fontWeight: "bold" }}>
                        APRESENTAÇÃO DOS PRODUTOS ENTREGUES
                      </span>
                      ?<span style={{ color: "#F00" }}>*</span>
                    </Typography>
                  </Box>
                  <Rating
                    onChange={(e, newValue) => {
                      //console.log("rating", newValue);
                      setRatingItems(newValue);
                    }}
                    size="large"
                    sx={{
                      marginTop: "10px",
                      marginBottom: "10px",
                      fontSize: "50px",
                    }}
                  />
                  {ratingItems && ratingItems < 5 && (
                    <Box>
                      <Typography variant="h5" sx={{ marginBottom: "10px" }}>
                        O que motivou a sua nota?
                      </Typography>
                      <TextField
                        fullWidth
                        placeholder="Digite sua resposta"
                        onChange={(e) => {
                          setRatingSuggestionItems(e.target.value);
                        }}
                      />
                    </Box>
                  )}
                </Box>
                {/* ITEM 1 */}
                <Box sx={{ marginBottom: "50px" }}>
                  <Box>
                    <Typography variant="h4">
                      3 - Como você avalia a{" "}
                      <span style={{ fontWeight: "bolder" }}>
                        QUALIDADE DOS NOSSOS PRODUTOS
                      </span>
                      ?<span style={{ color: "#F00" }}>*</span>
                    </Typography>
                  </Box>
                  <Rating
                    onChange={(e, newValue) => {
                      //console.log("rating", newValue);
                      setRatingQuality(newValue);
                    }}
                    size="large"
                    sx={{
                      marginTop: "10px",
                      marginBottom: "10px",
                      fontSize: "50px",
                    }}
                  />
                  {ratingQuality && ratingQuality < 5 && (
                    <Box>
                      <Typography variant="h5" sx={{ marginBottom: "10px" }}>
                        O que motivou a sua nota?
                      </Typography>
                      <TextField
                        fullWidth
                        placeholder="Digite sua resposta"
                        onChange={(e) => {
                          setRatingSuggestionQuality(e.target.value);
                        }}
                      />
                    </Box>
                  )}
                </Box>
                {/* ITEM 1 */}
                <Box sx={{ marginBottom: "50px" }}>
                  <Box>
                    <Typography variant="h4">
                      4 - Como você avalia a{" "}
                      <span style={{ fontWeight: "bold" }}>
                        FACILIDADE EM REALIZAR O SEU PEDIDO
                      </span>
                      ?<span style={{ color: "#F00" }}>*</span>
                    </Typography>
                  </Box>
                  <Rating
                    onChange={(e, newValue) => {
                      //console.log("rating", newValue);
                      setRatingDificulty(newValue);
                    }}
                    size="large"
                    sx={{
                      marginTop: "10px",
                      marginBottom: "10px",
                      fontSize: "50px",
                    }}
                  />
                  {ratingDificulty && ratingDificulty < 5 && (
                    <Box>
                      <Typography variant="h5" sx={{ marginBottom: "10px" }}>
                        O que motivou a sua nota?
                      </Typography>
                      <TextField
                        fullWidth
                        placeholder="Digite sua resposta"
                        onChange={(e) => {
                          setRatingSuggestionDificulty(e.target.value);
                        }}
                      />
                    </Box>
                  )}
                </Box>

                {/* ITEM 1 */}
                <Box sx={{ marginBottom: "50px" }}>
                  <Box>
                    <Typography variant="h4">
                      5 - Como você avalia nosso{" "}
                      <span style={{ fontWeight: "bolder" }}>
                        TEMPO DE ENTREGA
                      </span>
                      ?<span style={{ color: "#F00" }}>*</span>
                    </Typography>
                  </Box>
                  <Rating
                    onChange={(e, newValue) => {
                      //console.log("rating", newValue);
                      setRatingDeliveryTime(newValue);
                    }}
                    size="large"
                    sx={{
                      marginTop: "10px",
                      marginBottom: "10px",
                      fontSize: "50px",
                    }}
                  />
                  {ratingDeliveryTime && ratingDeliveryTime < 5 && (
                    <Box>
                      <Typography variant="h5" sx={{ marginBottom: "10px" }}>
                        O que motivou a sua nota?
                      </Typography>
                      <TextField
                        fullWidth
                        placeholder="Digite sua resposta"
                        onChange={(e) => {
                          setRatingSuggestionDeliveryTime(e.target.value);
                        }}
                      />
                    </Box>
                  )}
                </Box>
                {/* ITEM 1 */}
                <Box sx={{ marginBottom: "50px" }}>
                  <Box>
                    <Typography variant="h4">
                      6 - Como você avalia nosso{" "}
                      <span style={{ fontWeight: "bolder" }}>
                        CUSTO-BENEFÍCIO
                      </span>
                      ?<span style={{ color: "#F00" }}>*</span>
                    </Typography>
                  </Box>
                  <Rating
                    onChange={(e, newValue) => {
                      //console.log("rating", newValue);
                      setRatingPrice(newValue);
                    }}
                    size="large"
                    sx={{
                      marginTop: "10px",
                      marginBottom: "10px",
                      fontSize: "50px",
                    }}
                  />
                  {ratingPrice && ratingPrice < 5 && (
                    <Box>
                      <Typography variant="h5" sx={{ marginBottom: "10px" }}>
                        O que motivou a sua nota?
                      </Typography>
                      <TextField
                        fullWidth
                        placeholder="Digite sua resposta"
                        onChange={(e) => {
                          setRatingSuggestionPrice(e.target.value);
                        }}
                      />
                    </Box>
                  )}
                </Box>
                <Box sx={{ marginBottom: "50px" }}>
                  <Box>
                    <Typography
                      variant="h4"
                      sx={{
                        marginBottom: "10px",
                      }}
                    >
                      7 - O que faria você consumir na loja do{` `}
                      {state.branch.name}?
                    </Typography>
                  </Box>
                  <TextField
                    autoComplete="off"
                    fullWidth
                    onChange={(e) => {
                      setReasonToConsume(e.target.value);
                    }}
                  />
                </Box>
                <Box sx={{ marginBottom: "50px" }}>
                  <Box>
                    <Typography
                      variant="h4"
                      sx={{
                        marginBottom: "10px",
                      }}
                    >
                      8 - Em uma escala de 0 a 10, o quanto você recomendaria o{" "}
                      {state.branch.name} para um amigo ou familiar?
                      <span style={{ color: "#F00" }}>*</span>
                    </Typography>
                  </Box>
                  <RatingButtons />
                </Box>
                <Box sx={{ marginBottom: "50px" }}>
                  <Box>
                    <Typography
                      variant="h4"
                      sx={{
                        marginBottom: "15px",
                      }}
                    >
                      9 - Informe aqui sua sugestão ou elogio
                    </Typography>
                  </Box>
                  <TextField
                    multiline
                    fullWidth
                    rows={4}
                    sx={{ marginBottom: "10px" }}
                    onChange={(e) => setObservations(e.target.value)}
                  />
                </Box>
                <Button
                  fullWidth
                  variant="contained"
                  onClick={() => handleSubmitRating()}
                  disabled={
                    !ratingItems ||
                    !ratingDificulty ||
                    !ratingDeliveryTime ||
                    !ratingPrice ||
                    !ratingSupport ||
                    !ratingQuality ||
                    !recommendationScore ||
                    loadingButton
                  }
                >
                  {loadingButton ? "Aguarde..." : "Concluir"}
                </Button>
              </Box>
            </>
          ) : (
            <Box>
              {" "}
              <Box
                sx={{
                  textAlign: "center",
                  paddingTop: "50px",
                  marginBottom: "25px",
                }}
              >
                {/* <StoreLogoHeader /> */}
                <StoreLogo
                  logo={
                    state.company &&
                    state.company.settings &&
                    state.company.settings.logoURL
                  }
                />
              </Box>
              <Box sx={{ marginBottom: "10px" }}>
                <Typography variant="h4" sx={{ textAlign: "center" }}>
                  Obrigado por avaliar seu pedido!
                </Typography>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </>
  ) : (
    <Typography>Pedido não encontrado</Typography>
  );
};

export default OrderReviewPage;
