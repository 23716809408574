import React, { useState, useEffect } from "react";
import {
  Alert,
  Grid,
  Box,
  Typography,
  Snackbar,
  IconButton,
  Button,
  Stack,
  TextField,
} from "@mui/material";
import currency from "currency-formatter";
import { useApp } from "../../context/appContext";
import { ArrowForwardIosRounded } from "@mui/icons-material";
import { RemoveCircle } from "@mui/icons-material";
import { Delete } from "@mui/icons-material";
import InputMask from "react-input-mask";
import { cpf } from "cpf-cnpj-validator";
import ErrorIcon from "@mui/icons-material/Error";
import EmojiEmotionsIcon from "@mui/icons-material/EmojiEmotions";

const TaxPayerInfo = ({ taxPayerId, setTaxPayerId }) => {
  const { state, applyCoupon } = useApp();

  const [couponCode, setCouponCode] = useState("");
  const [validCPF, setValidCPF] = useState(true);
  const [snack, setSnack] = useState(false);
  const [cpfSnack, setCpfSnack] = useState(false);

  const checkCoupon = () => {
    const coupons = state.coupons.filter(
      (c) => c.rules.couponCode.toLowerCase() === couponCode.toLowerCase()
    );

    //FAZER A CONSULTA SE O CUPOM É ENTREGA AQUI

    if (coupons.length !== 0) {
      //console.log("cupom válido", couponCode);
      applyCoupon(coupons[0]);
    } else {
      //console.log("cupom inválido", couponCode);
      applyCoupon(null);
    }
  };

  const removeCoupon = () => {
    applyCoupon(null);
    setCouponCode("");
  };

  useEffect(() => {
    if (taxPayerId && taxPayerId !== "") {
      if (cpf.isValid(taxPayerId)) {
        console.log("valid cpf", taxPayerId);
        setValidCPF(true);
        setSnack(false);
        setCpfSnack(true);
      } else {
        console.log("invalid cpf", taxPayerId);
        setValidCPF(false);
        setSnack(true);
        setCpfSnack(false);
      }
    } else {
      console.log("empty taxpayerid");
      setValidCPF(true);
    }
  }, [taxPayerId]);

  return (
    <>
      <Snackbar
        open={cpfSnack}
        autoHideDuration={4000}
        anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
        onClose={() => setCpfSnack(!cpfSnack)}
      >
        <Alert
          iconMapping={{
            info: <EmojiEmotionsIcon fontSize="inherit" />,
          }}
          severity="success"
        >
          <strong>CPF válido!</strong>
          <br />O número informado é válido.
        </Alert>
      </Snackbar>
      <Snackbar
        open={snack}
        autoHideDuration={4000}
        anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
        onClose={() => setSnack(!snack)}
      >
        <Alert
          iconMapping={{
            info: <ErrorIcon fontSize="inherit" />,
          }}
          severity="error"
        >
          <strong>CPF inválido</strong>
          <br />
          Por favor, informe um CPF válido.
        </Alert>
      </Snackbar>
      <Grid item xs={12} lg={12} style={{ marginTop: "25px" }}>
        <>
          <Box
            style={{
              backgroundColor: "#ecf7ff",
              padding: "15px",
              borderRadius: "5px",
            }}
          >
            <Typography
              variant="h6"
              sx={{
                fontWeight: "600",
              }}
            >
              Deseja colocar o CPF na nota?
            </Typography>
          </Box>
          <Box display="flex" alignItems="center">
            <Grid container>
              <Grid item sm={12} md={12}>
                <InputMask
                  //style={{ marginTop: "5px" }}
                  mask="99999999999"
                  name="taxPayerId"
                  value={taxPayerId}
                  disabled={false}
                  maskChar=" "
                  onChange={(e) => setTaxPayerId(e.target.value)}
                >
                  {() => (
                    <TextField
                      autoComplete="off"
                      style={{
                        marginTop: "25px",
                      }}
                      id="default-textarea"
                      label="Digite seu CPF"
                      variant="outlined"
                      fullWidth
                      sx={{
                        mb: 2,
                      }}
                    />
                  )}
                </InputMask>
              </Grid>
            </Grid>
          </Box>
          {/* <Box
          style={{
            marginBottom: "15px",
            border: "1px solid #ecf7ff",
            padding: "15px",
          }}
        ></Box> */}
        </>
      </Grid>
    </>
  );
};
export default TaxPayerInfo;
